import React, { useEffect, useRef } from "react";
import One from "../../assets/images/images/logo/image1.png";
import Two from "../../assets/images/images/logo/image2.png";
import Three from "../../assets/images/images/logo/image3.png";
import Four from "../../assets/images/images/logo/image4.png";
import Five from "../../assets/images/images/logo/image5.png";
import Six from "../../assets/images/images/logo/image6.png";
import Seven from "../../assets/images/images/logo/image7.png";
import Eight from "../../assets/images/images/logo/image8.png";
import Nine from "../../assets/images/images/logo/image9.png";
import Ele from "../../assets/images/images/logo/image11.png";
import Twl from "../../assets/images/images/logo/image12.png";
import Thir from "../../assets/images/images/logo/image13.png";
import Fourt from "../../assets/images/images/logo/image14.png";
// import Fift from "../../assets/images/images/logo/image15.png";
import Sixt from "../../assets/images/images/logo/image16.png";
import Sevent from "../../assets/images/images/logo/image17.png";
import Nint from "../../assets/images/images/logo/image18.png";
import Twen from "../../assets/images/images/logo/image19.png";
import TwenO from "../../assets/images/images/logo/image20.png";
import TwenT from "../../assets/images/images/logo/image21.png";
import TwenTh from "../../assets/images/images/logo/image22.png";
import TwenF from "../../assets/images/images/logo/image23.png";
import TwenFiv from "../../assets/images/images/logo/image24.png";

const BrandList1 = [
  {
    image: One,
  },
  {
    image: Two,
  },
  {
    image: Three,
  },
  {
    image: Four,
  },
  {
    image: Five,
  },
  {
    image: Six,
  },
  {
    image: Seven,
  },
 
];
const BrandList2 = [
  {
    image: Eight,
  },
  {
    image: Nine,
  },

  {
    image: Ele,
  },
  {
    image: Twl,
  },
  {
    image: Thir,
  },
  {
    image: Fourt,
  },

  {
    image: Sixt,
  },
];
const BrandList3 = [
  {
    image: Sevent,
  },
  {
    image: Nint,
  },
  {
    image: Twen,
  },
  {
    image: TwenO,
  },
  {
    image: TwenT,
  },
  {
    image: TwenTh,
  },
  {
    image: TwenF,
  },
  {
    image: TwenFiv,
  },
];
const BrandThree = ({ brandStyle, isMobileView }) => {
  return (
    <>
      <div class="slideres">
        <div class="slide-track">
          {[...BrandList1, ...BrandList1].map((data, index) => (
            <div class="slide" style={{ margin: "15px" }}>
                <img
                  key={index}
                  src={data.image}
                  alt="Brand"
                  style={{ height: "80px", width: isMobileView ? '' : "100%" }}
                />
            </div>
          ))}
        </div>
      </div>
      <div class="slideres mt-4">
        <div class="slide-tracks">
          {[...BrandList2, ...BrandList2,].map((data, index) => (
            <div class="slide" style={{ margin: "15px" }}>
                <img
                  key={index}
                  src={data.image}
                  alt="Brand"
                  style={{ height: "80px", width: isMobileView ? '' : "100%"  }}
                />
            </div>
          ))}
        </div>
      </div>
      <div class="slideres mt-4">
        <div class="slide-track">
          {[...BrandList3, ...BrandList3].map((data, index) => (
            <div class="slide" style={{ margin: "15px" }}>
                <img
                  key={index}
                  src={data.image}
                  alt="Brand"
                  style={{ height: "80px", width: isMobileView ? '' : "100%"  }}
                />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default BrandThree;
