import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PageScrollTop from "./components/pageToTop/PageScrollTop";

// Pages import Here
import DigitalAgency from "./pages";
import AboutUs from "./pages/AboutUs";
import Service from "./elements/service/Service";
import Contact from "./elements/contact/Contact";
import BlogDetailsContent from "./components/blogs/BlogDetailsContent";

// Import Css Here
import "./assets/scss/style.scss";

const App = () => {
  return (
    <Router>
      <PageScrollTop>
        <Switch>
          <Route path={"/"} exact component={DigitalAgency} />
          <Route exact path={"/portfolio"} component={AboutUs} />
          <Route path={"/service"} exact component={Service} />
          <Route path={"/contact"} exact component={Contact} />
          <Route path={"/portfolio/:id"} component={BlogDetailsContent} />
        </Switch>
      </PageScrollTop>
    </Router>
  );
};

export default App;
