import React, { useEffect, useState } from "react";
import SEO from "../common/SEO";
import HeaderTwo from "../common/header/HeaderTwo";
import FooterFour from "../common/footer/FooterFour";
import One from "../assets/images/images/vivo/vaio1.webp";
import Two from "../assets/images/images/tcl-roadshow/tcl2.webp";
import Three from "../assets/images/images/tcl-retail/2.webp";
import Four from "../assets/images/images/mi-sdg-retail/main.webp";
import Five from "../assets/images/images/kenwood-delongh-retail/Main.webp";
import Six from "../assets/images/images/delonghi-jashanmall/8.webp";
import Seven from "../assets/images/images/victorianox/vic1.webp";
import Eight from "../assets/images/images/mi-note-8/9.webp";
import Nine from "../assets/images/images/mi-sdg-retail/11.webp";
import Ten from "../assets/images/images/hoover-retail/6.webp";

import Slider from "react-slick";
import { BannerActivation } from "../utils/script";
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import BrandList from "../elements/brand/BrandThree";

const BannerData = [
  {
    image: { One },
    title: "Innovate. Create. Captivate.",
    description:
      "Our team of creative strategists and designers collaborate closely with clients to develop unique brand identities and design solutions that resonate with their target audiences. We go beyond aesthetics to create strategic brand experiences that drive meaningful connections.There are many variations variats.",
  },
  {
    image: { Two },
    title: "Design with Purpose, Inspire with Impact.        ",
    description:
      "With our expertise in designing and fabrication, we create bespoke exhibition and retail environments that showcase your brand's unique identity and attract attention. Through strategic design and placement, we enhance visibility and create engaging experiences for customers.",
  },
  {
    image: { Three },
    title: "Transforming Visions into Experiences.      ",
    description:
      "From large-scale product launches to intimate brand experiences and training programs, our experienced event management team brings your vision to life. We meticulously plan, curate, and execute immersive event experiences that leave a lasting impact on attendees and elevate your brand.",
  },
];

const DigitalAgency = () => {
  const [isMobileView, setIsMobileView] = useState(false);
  const [isTabletView, setIsTabletView] = useState(false);
  const [isDesktopView, setIsDesktopView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      const isMobile = windowWidth <= 767;
      const isTablet = windowWidth >= 768 && windowWidth <= 1023;
      const isDesktop = windowWidth >= 1024;

      setIsMobileView(isMobile);
      setIsTabletView(isTablet);
      setIsDesktopView(isDesktop);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <SEO title="Masterstroqe" />
      <main className="page-wrapper">
        <HeaderTwo btnStyle="btn-small" HeaderSTyle="header-transparent" />

        {/* Start Slider Area  */}
        <Slider
          className="slider-area slider-style-4 slider-dot rn-slick-dot rn-slick-arrow"
          {...BannerActivation}
        >
          {BannerData.map((data, index) => (
            <div key={index} className="single-slide">
              <div
                className="height-950 bg-overlay bg_image"
                style={{
                  backgroundImage: `url(${data.image})`,
                }}
              >
                <div className="container">
                  <div className="row row--30 align-items-center">
                    <div className="order-2 order-lg-1 col-lg-8">
                      <div className="inner text-left">
                        <h1
                          className="title"
                          dangerouslySetInnerHTML={{ __html: data.title }}
                        ></h1>
                        <p
                          className="description"
                          dangerouslySetInnerHTML={{ __html: data.description }}
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        {/* End Slider Area  */}

        {/* <div className="rwt-portfolio-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Why Choose Us"
                  title="Companies Choose us!"
                  description="There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration."
                />
              </div>
            </div>
            <PortfolioOne Column="col-lg-6 col-md-6 col-sm-6 col-12 mt--30 portfolio no-overlay" />
          </div>
        </div> */}

        {/* <Separator />
        <div className="rwt-testimonial-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Client Feedback"
                  title="What People Are Saying."
                  description="There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration."
                />
              </div>
            </div>
            <TestimonialOne
              column="col-lg-4 col-md-6 col-sm-6 col-12 mt--30"
              teamStyle="card-style-default testimonial-style-one style-two"
            />
          </div>
        </div> */}

        <div className="blog-area" style={{ marginTop: "50px" }}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Latest Projects"
                  title="Some Recent Trending Projects."
                  description="Partner with Masterstroqe to unleash the power of your brand."
                />
              </div>
            </div>
            <div className="col-lg-12">
              {/* <CircleProgress /> */}
              <div className="rwt-brand-area pb--60 pt--30">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-12 mt--10">
                      <div className="photobanner">
                        <div className="photobanner__wrapper">
                          <div className="photobanner__inner">
                            <img src={One} alt="" />
                            <img src={Two} alt="" />
                            <img src={Three} alt="" />
                            <img src={Four} alt="" />
                            <img src={Five} alt="" />
                            <img src={Six} alt="" />
                            <img src={Seven} alt="" />
                            <img src={Eight} alt="" />
                            <img src={Nine} alt="" />
                            <img src={Ten} alt="" />
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="rwt-progressbar-area rn-section-gap">
          <div className="container-fluid">
            <div className="row mb--25">
              <div className="col-lg-10 offset-lg-1">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Partnerships"
                  title="Our Partnerships.                  "
                  description={`Masterstroqe has successfully collaborated with numerous clients, 
                  achieving remarkable results in retail and exhibition solutions, branding and event management.`}
                />
              </div>
            </div>
            <div className="">
              {/* <CircleProgress /> */}
              <div className="rwt-brand-area pb--60 pt--30">
                <BrandList brandStyle="brand-style-2" isMobileView={isMobileView} />
              </div>
            </div>
          </div>
        </div>
        <Separator />

        <div className="rwt-progressbar-area rn-section-gap">
          <div className="container">
            <div className="row mb--25">
              <div className="col-lg-10 offset-lg-1">
                {/* <div className="container-animate">
                  Hi There <div className="typewriters">{" "} I'm Front end Developer</div>
                </div> */}

                <div className={isMobileView ? "container-animate" : "slider"}>
                  <div className={isMobileView ? 'mobile-caption ' : 'caption'} style={{fontSize: isMobileView && '50px', transform: isMobileView && 'translateY(-50%)', color: '#fff'}}>
                    Let’s talk about{" "}
                    {!isMobileView && (
                      <div className="text-box">
                        <div>strategy</div>
                        <div>designing</div>
                        <div>fabrication</div>
                        <div>activations</div>
                        <div>management</div>
                      </div>
                    )}
                  </div>
                  {isMobileView && (
                    <div className="text-box mt-1" style={{fontSize: '50px', color: '#fff', marginTop: '10px'}}>
                    <div>strategy</div>
                    <div>designing</div>
                    <div>fabrication</div>
                    <div>activations</div>
                    <div>management</div>
                  </div>
                  )}
                </div>
                <div id="rssBlock">
                  <p className={isMobileView ? 'cnnContents-mobile' : 'cnnContents'}>
                    <span className="marqueeStyle">
                      &nbsp; EXHIBITION, EVENTS, TECHNOLOGY, DESIGNS,{" "}
                    </span>
                    <span className="marqueeStyle2">
                      EXHIBITION, EVENTS, TECHNOLOGY, DESIGNS,
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Separator />
        {/* <Testimonial /> */}
        <FooterFour />
      </main>
    </>
  );
};
export default DigitalAgency;
