//Vivo 1
import Vivo1 from "../../assets/images/images/vivo/vaio1.webp";
import Vivo2 from "../../assets/images/images/vivo/vaio2.webp";
import Vivo3 from "../../assets/images/images/vivo/vaio3.webp";
import Vivo4 from "../../assets/images/images/vivo/vaio4.webp";
import Vivo5 from "../../assets/images/images/vivo/vaio5.webp";
import Vivo6 from "../../assets/images/images/vivo/vaio6.webp";
import Vivo7 from "../../assets/images/images/vivo/vaio7.webp";

//Ariete Kendwood 2
import Ak1 from "../../assets/images/images/kenwood-ariete/1.webp";
import Ak2 from "../../assets/images/images/kenwood-ariete/2.webp";
import Ak3 from "../../assets/images/images/kenwood-ariete/3.webp";
import Ak4 from "../../assets/images/images/kenwood-ariete/main.webp";

//Delohied 3
import Del1 from "../../assets/images/images/delongh-retail/1.webp";
import Del2 from "../../assets/images/images/delongh-retail/2.webp";
import Del3 from "../../assets/images/images/delongh-retail/3.webp";
import Del4 from "../../assets/images/images/delongh-retail/4.webp";
import Del5 from "../../assets/images/images/delongh-retail/5.webp";
import Del6 from "../../assets/images/images/delongh-retail/MAIN.webp";

//Delhoed jashmall 4
import Dj1 from "../../assets/images/images/delonghi-jashanmall/1.webp";
import Dj2 from "../../assets/images/images/delonghi-jashanmall/2.webp";
import Dj3 from "../../assets/images/images/delonghi-jashanmall/3.webp";
import Dj4 from "../../assets/images/images/delonghi-jashanmall/4.webp";
import Dj5 from "../../assets/images/images/delonghi-jashanmall/5.webp";
import Dj6 from "../../assets/images/images/delonghi-jashanmall/6.webp";
import Dj7 from "../../assets/images/images/delonghi-jashanmall/7.webp";
import Dj8 from "../../assets/images/images/delonghi-jashanmall/8.webp";
import Dj9 from "../../assets/images/images/delonghi-jashanmall/main.webp";
import Dj10 from "../../assets/images/images/delonghi-jashanmall/mains.webp";

//TCL RoadShow 5
import Tc1 from "../../assets/images/images/tcl-roadshow/tcl1.webp";
import Tc2 from "../../assets/images/images/tcl-roadshow/tcl2.webp";
import Tc3 from "../../assets/images/images/tcl-roadshow/tcl3.webp";
import Tc4 from "../../assets/images/images/tcl-roadshow/tcl4.webp";
import Tc5 from "../../assets/images/images/tcl-roadshow/tcl5.webp";
import Tc6 from "../../assets/images/images/tcl-roadshow/tcl6.webp";

// TCL Global 6
import Tg1 from "../../assets/images/images/tcl-retail/1.webp";
import Tg2 from "../../assets/images/images/tcl-retail/2.webp";
import Tg3 from "../../assets/images/images/tcl-retail/3.webp";
import Tg4 from "../../assets/images/images/tcl-retail/4.webp";
import Tg5 from "../../assets/images/images/tcl-retail/5.webp";
import Tg6 from "../../assets/images/images/tcl-retail/main.webp";

//Jashnmall-timesqure 7
import Jt1 from "../../assets/images/images/jashnmal-timesquare/1.webp";
import Jt2 from "../../assets/images/images/jashnmal-timesquare/2.webp";
import Jt3 from "../../assets/images/images/jashnmal-timesquare/3.webp";
import Jt4 from "../../assets/images/images/jashnmal-timesquare/4.webp";
import Jt5 from "../../assets/images/images/jashnmal-timesquare/5.webp";
import Jt6 from "../../assets/images/images/jashnmal-timesquare/6.webp";
import Jt7 from "../../assets/images/images/jashnmal-timesquare/7.webp";
import Jt8 from "../../assets/images/images/jashnmal-timesquare/8.webp";
import Jt9 from "../../assets/images/images/jashnmal-timesquare/9.webp";
import Jt10 from "../../assets/images/images/jashnmal-timesquare/10.webp";
import Jt11 from "../../assets/images/images/jashnmal-timesquare/11.webp";
import Jt12 from "../../assets/images/images/jashnmal-timesquare/12.webp";
import Jt13 from "../../assets/images/images/jashnmal-timesquare/13.webp";
import Jt14 from "../../assets/images/images/jashnmal-timesquare/14.webp";
import Jt15 from "../../assets/images/images/jashnmal-timesquare/15.webp";
import Jt16 from "../../assets/images/images/jashnmal-timesquare/16.webp";
import Jt17 from "../../assets/images/images/jashnmal-timesquare/17.webp";
import Jt18 from "../../assets/images/images/jashnmal-timesquare/18.webp";
import Jt19 from "../../assets/images/images/jashnmal-timesquare/19.webp";
import Jt20 from "../../assets/images/images/jashnmal-timesquare/20.webp";
import Jt21 from "../../assets/images/images/jashnmal-timesquare/21.webp";
import Jt22 from "../../assets/images/images/jashnmal-timesquare/22.webp";
import Jt23 from "../../assets/images/images/jashnmal-timesquare/23.webp";
import Jt24 from "../../assets/images/images/jashnmal-timesquare/24.webp";
import JtMain from "../../assets/images/images/jashnmal-timesquare/main.webp";

//Ariete Iceland 8
import Ai1 from "../../assets/images/images/ariete/1.webp";
import Ai2 from "../../assets/images/images/ariete/2.webp";
import Ai3 from "../../assets/images/images/ariete/3.webp";
import Ai4 from "../../assets/images/images/ariete/4.webp";
import Ai5 from "../../assets/images/images/ariete/5.webp";
import Ai6 from "../../assets/images/images/ariete/6.webp";
import Ai7 from "../../assets/images/images/ariete/7.webp";
import Ai8 from "../../assets/images/images/ariete/main.webp";

//Mi Xiomi sdg 9
import Mx1 from "../../assets/images/images/mi-sdg-retail/main.webp";
import Mx2 from "../../assets/images/images/mi-sdg-retail/1.webp";
import Mx3 from "../../assets/images/images/mi-sdg-retail/2.webp";
import Mx4 from "../../assets/images/images/mi-sdg-retail/3.webp";
import Mx5 from "../../assets/images/images/mi-sdg-retail/4.webp";
import Mx6 from "../../assets/images/images/mi-sdg-retail/5.webp";
import Mx7 from "../../assets/images/images/mi-sdg-retail/6.webp";
import Mx8 from "../../assets/images/images/mi-sdg-retail/7.webp";
import Mx9 from "../../assets/images/images/mi-sdg-retail/8.webp";
import Mx10 from "../../assets/images/images/mi-sdg-retail/9.webp";
import Mx11 from "../../assets/images/images/mi-sdg-retail/10.webp";
import Mx12 from "../../assets/images/images/mi-sdg-retail/11.webp";
import Mx13 from "../../assets/images/images/mi-sdg-retail/12.webp";
import Mx14 from "../../assets/images/images/mi-sdg-retail/13.webp";
import Mx15 from "../../assets/images/images/mi-sdg-retail/14.webp";
import Mx16 from "../../assets/images/images/mi-sdg-retail/15.webp";

//Hoover 10
import Ho1 from "../../assets/images/images/hoover-retail/main.webp";
import Ho2 from "../../assets/images/images/hoover-retail/1.webp";
import Ho3 from "../../assets/images/images/hoover-retail/2.webp";
import Ho4 from "../../assets/images/images/hoover-retail/3.webp";
import Ho5 from "../../assets/images/images/hoover-retail/4.webp";
import Ho6 from "../../assets/images/images/hoover-retail/5.webp";
import Ho7 from "../../assets/images/images/hoover-retail/6.webp";

//Hoover 10
import Kn1 from "../../assets/images/images/kenwood-delongh-retail/Main.webp";
import Kn2 from "../../assets/images/images/hoover-retail/1.webp";
import Kn3 from "../../assets/images/images/hoover-retail/3.webp";
import Kn4 from "../../assets/images/images/hoover-retail/4.webp";
import Kn5 from "../../assets/images/images/hoover-retail/5.webp";

//Mi Note 8 11
import Mi1 from "../../assets/images/images/mi-note-8/main.webp";
import Mi2 from "../../assets/images/images/mi-note-8/1.webp";
import Mi3 from "../../assets/images/images/mi-note-8/2.webp";
import Mi4 from "../../assets/images/images/mi-note-8/3.webp";
import Mi5 from "../../assets/images/images/mi-note-8/4.webp";
import Mi6 from "../../assets/images/images/mi-note-8/5.webp";
import Mi7 from "../../assets/images/images/mi-note-8/6.webp";
import Mi8 from "../../assets/images/images/mi-note-8/7.webp";
import Mi9 from "../../assets/images/images/mi-note-8/8.webp";
import Mi10 from "../../assets/images/images/mi-note-8/9.webp";
import Mi11 from "../../assets/images/images/mi-note-8/10.webp";
import Mi12 from "../../assets/images/images/mi-note-8/11.webp";
import Mi13 from "../../assets/images/images/mi-note-8/12.webp";
import Mi14 from "../../assets/images/images/mi-note-8/13.webp";
import Mi15 from "../../assets/images/images/mi-note-8/14.webp";
import Mi16 from "../../assets/images/images/mi-note-8/15.webp";

//Mi Xiomi mall 12
import Mxi2 from "../../assets/images/images/mi-retail/main.webp";
import Mxi3 from "../../assets/images/images/mi-retail/1.webp";
import Mxi4 from "../../assets/images/images/mi-retail/2.webp";
import Mxi5 from "../../assets/images/images/mi-retail/3.webp";
import Mxi6 from "../../assets/images/images/mi-retail/4.webp";
import Mxi7 from "../../assets/images/images/mi-retail/5.webp";
import Mxi8 from "../../assets/images/images/mi-retail/6.webp";
import Mxi9 from "../../assets/images/images/mi-retail/7.webp";
import Mxi10 from "../../assets/images/images/mi-retail/8.webp";
import Mxi11 from "../../assets/images/images/mi-retail/9.webp";
import Mxi12 from "../../assets/images/images/mi-retail/10.webp";
import Mxi13 from "../../assets/images/images/mi-retail/11.webp";
import Mxi14 from "../../assets/images/images/mi-retail/12.webp";
import Mxi15 from "../../assets/images/images/mi-retail/13.webp";
import Mxi16 from "../../assets/images/images/mi-retail/14.webp";
import Mxi17 from "../../assets/images/images/mi-retail/15.webp";

//Hoover 13
import Hoo2 from "../../assets/images/images/hooverC4/main.webp";
import Hoo3 from "../../assets/images/images/hooverC4/1.webp";
import Hoo4 from "../../assets/images/images/hooverC4/2.webp";
import Hoo5 from "../../assets/images/images/hooverC4/3.webp";
import Hoo6 from "../../assets/images/images/hooverC4/4.webp";
import Hoo7 from "../../assets/images/images/hooverC4/5.webp";
import Hoo8 from "../../assets/images/images/hooverC4/6.webp";
import Hoo9 from "../../assets/images/images/hooverC4/7.webp";

// Victoria Nox 14
import Vn1 from "../../assets/images/images/victorianox/vic5.webp";
import Vn2 from "../../assets/images/images/victorianox/vic1.webp";
import Vn3 from "../../assets/images/images/victorianox/vic2.webp";
import Vn4 from "../../assets/images/images/victorianox/vic3.webp";
import Vn5 from "../../assets/images/images/victorianox/vic4.webp";
import Vn6 from "../../assets/images/images/victorianox/vic5.webp";
import Vn7 from "../../assets/images/images/victorianox/vic6.webp";

//Kenwood 15
import K1 from "../../assets/images/images/kenwood-delongh-dfc/Main.webp";
import K2 from "../../assets/images/images/kenwood-delongh-dfc/1.webp";
import K3 from "../../assets/images/images/kenwood-delongh-dfc/2.webp";

//Electronox 16
import E1 from "../../assets/images/images/electrolux/main.webp";
import E2 from "../../assets/images/images/electrolux/1.webp";

//Kenwood 17
import Ken1 from "../../assets/images/images/kenwood-oman/Kenwood1.webp";
import Ken2 from "../../assets/images/images/kenwood-oman/Kenwood2.webp";
import Ken3 from "../../assets/images/images/kenwood-oman/Kenwood3.webp";
import Ken4 from "../../assets/images/images/kenwood-oman/Kenwood4.webp";
import Ken5 from "../../assets/images/images/kenwood-oman/Kenwood5.webp";
import Ken6 from "../../assets/images/images/kenwood-oman/Kenwood6.webp";
import Ken7 from "../../assets/images/images/kenwood-oman/Kenwood7.webp";
import Ken8 from "../../assets/images/images/kenwood-oman/Kenwood8.webp";
import Ken9 from "../../assets/images/images/kenwood-oman/Kenwood9.webp";

import main from "../../assets/images/images/red-magic/red6.webp";
import Rd1 from "../../assets/images/images/red-magic/red1.webp";
import Rd2 from "../../assets/images/images/red-magic/red2.webp";
import Rd3 from "../../assets/images/images/red-magic/red3.webp";
import Rd4 from "../../assets/images/images/red-magic/red4.webp";
import Rd5 from "../../assets/images/images/red-magic/redmain.webp";
import Rd6 from "../../assets/images/images/red-magic/red5.webp";
import Rd7 from "../../assets/images/images/red-magic/red6.webp";
import Rd8 from "../../assets/images/images/red-magic/red7.webp";
import Rd9 from "../../assets/images/images/red-magic/red8.webp";
import Rd10 from "../../assets/images/images/red-magic/red9.webp";

//Microsoft
import Mir from "../../assets/images/images/microsoft-copilot-launch/main.png";
import Mir1 from "../../assets/images/images/microsoft-copilot-launch/video1.mp4";

//Delongi Coffee day
import Delc1 from "../../assets/images/images/delongi-world-of-coffee-day/main.png";
import Delc2 from "../../assets/images/images/delongi-world-of-coffee-day/video.mp4";
import Delc3 from "../../assets/images/images/delongi-world-of-coffee-day/video1.mp4";

//delsey roadshow
import Delr1 from "../../assets/images/images/delsey-roadshow/1.webp";
import Delr2 from "../../assets/images/images/delsey-roadshow/2.webp";
import Delr3 from "../../assets/images/images/delsey-roadshow/3.webp";
import Delr4 from "../../assets/images/images/delsey-roadshow/4.webp";
import Delr5 from "../../assets/images/images/delsey-roadshow/5.webp";
import Delr6 from "../../assets/images/images/delsey-roadshow/6.webp";
import Delr7 from "../../assets/images/images/delsey-roadshow/7.webp";
import Delr8 from "../../assets/images/images/delsey-roadshow/video.mp4";

export const portfolioData = [
  {
    id: 1,
    portfolioImage: Mir,
    image: Mir,
    // title: "Vaio Product Launch",
    category: "Event",
    gallery: {
      imageTwo: Mir1,
    },
  },

  {
    id: 2,
    portfolioImage: Vivo1,
    image: Vivo1,
    title: "Vaio Product Launch",
    category: "Event",
    gallery: {
      imageOne: Vivo1,
      imageTwo: Vivo2,
      imageThree: Vivo3,
      imageFour: Vivo4,
      imageFive: Vivo5,
      imageSix: Vivo6,
      imageSeven: Vivo7,
    },
  },
  {
    id: 3,
    portfolioImage: Tc1,
    image: Tc1,
    title: "TCL Roadshow, Deira City Centre",
    category: "Event",
    gallery: {
      imageOne: Tc1,
      imageTwo: Tc2,
      imageThree: Tc3,
      imageFour: Tc4,
      imageFive: Tc5,
      imageSix: Tc6,
    },
  },
  {
    id: 4,
    portfolioImage: Tg2,
    image: Tg2,
    title: "TCL Island Stand, Sharaf DG Times Square Center",
    category: "Event",
    gallery: {
      imageOne: Tg1,
      imageTwo: Tg2,
      imageThree: Tg3,
      imageFour: Tg4,
      imageFive: Tg5,
      imageSix: Tg6,
    },
  },
  {
    id: 5,
    portfolioImage: Mx1,
    image: Mx1,
    title: "MI Branding",
    category: "Event",
    gallery: {
      imageOne: Mx1,
      imageTwo: Mx2,
      imageThree: Mx3,
      imageFour: Mx4,
      imageFive: Mx5,
      imageSix: Mx6,
      imageSeven: Mx7,
      imageEight: Mx8,
      imageNine: Mx9,
      imageTen: Mx10,
      imageEleven: Mx11,
      imageTwelve: Mx12,
      imageThirteen: Mx13,
      imageFourteen: Mx14,
      imageFifteen: Mx15,
      imageSixteen: Mx16,
    },
  },
  {
    id: 6,
    portfolioImage: Mxi2,
    image: Mxi2,
    title: "MI Stand And Branding",
    category: "Event",
    gallery: {
      imageOne: Mxi2,
      imageTwo: Mxi3,
      imageThree: Mxi4,
      imageFour: Mxi5,
      imageFive: Mxi6,
      imageSix: Mxi7,
      imageSeven: Mxi8,
      imageEight: Mxi9,
      imageNine: Mxi10,
      imageTen: Mxi11,
      imageEleven: Mxi12,
      imageTwelve: Mxi13,
      imageThirteen: Mxi14,
      imageFourteen: Mxi15,
      imageFifteen: Mxi16,
      imageSixteen: Mxi17,
    },
  },
  {
    id: 7,
    portfolioImage: Kn1,
    image: Kn1,
    title: "MI Stand And Branding",
    category: "Event",
    gallery: {
      imageOne: Kn1,
      imageTwo: Kn2,
      imageThree: Kn3,
      imageFour: Kn4,
      imageFive: Kn5,
    },
  },
  

  {
    id: 8,
    portfolioImage: K1,
    image: K1,
    title: "Kenwood End Cap",
    category: "Event",
    gallery: {
      imageOne: K1,
      imageTwo: K2,
      imageThree: K3,
    },
  },
  {
    id: 9,
    portfolioImage: Delc1,
    image: Delc1,
    // title: "Vaio Product Launch",
    category: "Event",
    gallery: {
      imageTwo: Delc2,
      imageThree: Delc3,
    },
  },
  {
    id: 10,
    portfolioImage: Ak4,
    image: Ak4,
    title: "Kenwood Ariete Arch Branding, Carrefour",
    category: "Event",
    gallery: {
      imageOne: Ak4,
      imageTwo: Ak1,
      imageThree: Ak2,
      imageFour: Ak3,
    },
  },

  {
    id: 11,
    portfolioImage: JtMain,
    image: JtMain,
    title: "jashanmal Brand Zone, Sharaf DG Times Square Center",
    category: "Event",
    gallery: {
      imageOne: Jt1,
      imageTwo: Jt2,
      imageThree: Jt3,
      imageFour: Jt4,
      imageFive: Jt5,
      imageSix: Jt6,
      imageSeven: Jt7,
      imageEight: Jt8,
      imageNine: Jt9,
      imageTen: Jt10,
      imageEleven: Jt11,
      imageTwelve: Jt12,
      imageThirteen: Jt13,
      imageFourteen: Jt14,
      imageFifteen: Jt15,
      imageSixteen: Jt16,
      imageSeventeen: Jt17,
      imageEighteen: Jt18,
      imageNineteen: Jt19,
      imageTwenty: Jt20,
      imageTwentyOne: Jt21,
      imageTwentyTwo: Jt22,
      imageTwentyThree: Jt23,
      imageTwentyFour: Jt24,
    },
  },
  {
    id: 12,
    portfolioImage: Ho1,
    image: Ho1,
    title: "Hoover One Power Cordless Stands",
    category: "Event",
    gallery: {
      imageOne: Ho1,
      imageTwo: Ho2,
      imageThree: Ho3,
      imageFour: Ho4,
      imageFive: Ho5,
      imageSix: Ho6,
      imageSeven: Ho7,
    },
  },
  

  {
    id: 13,
    portfolioImage: Hoo2,
    image: Hoo2,
    title: "Hoover Carrefour Endcaps",
    category: "Event",
    gallery: {
      imageOne: Hoo2,
      imageTwo: Hoo3,
      imageThree: Hoo4,
      imageFour: Hoo5,
      imageFive: Hoo6,
      imageSix: Hoo7,
      imageSeven: Hoo8,
      imageEight: Hoo9,
    },
  },
  {
    id: 14,
    portfolioImage: E1,
    image: E1,
    title: "Kenwood End Cap",
    category: "Event",
    gallery: {
      imageOne: E1,
      imageTwo: E2,
    },
  },
  {
    id: 15,
    portfolioImage: Delr1,
    image: Delr1,
    // title: "Vaio Product Launch",
    category: "Event",
    gallery: {
      imageOne: Delr8,
      imageTwo: Delr2,
      imageThree: Delr3,
      imageFour: Delr4,
      imageFive: Delr5,
      imageSix: Delr6,
      imageSeven: Delr7,
    },
  },
  {
    id: 16,
    portfolioImage: Del6,
    image: Del6,
    title: "Delonghi Island Stands",
    category: "Event",
    gallery: {
      imageOne: Del1,
      imageTwo: Del2,
      imageThree: Del3,
      imageFour: Del4,
      imageFive: Del5,
      imageSix: Del6,
    },
  },
  {
    id: 17,
    portfolioImage: Dj8,
    image: Dj8,
    title: "Delonghi Retail Solutions",
    category: "Event",
    gallery: {
      imageOne: Dj1,
      imageTwo: Dj2,
      imageThree: Dj3,
      imageFour: Dj4,
      imageFive: Dj5,
      imageSix: Dj6,
      imageSeven: Dj7,
      imageEight: Dj8,
      imageNine: Dj9,
      imageTen: Dj10,
    },
  },

  {
    id: 18,
    portfolioImage: Ai8,
    image: Ai8,
    title: "Ariete Island Stands",
    category: "Event",
    gallery: {
      imageOne: Ai1,
      imageTwo: Ai2,
      imageThree: Ai3,
      imageFour: Ai4,
      imageFive: Ai5,
      imageSix: Ai6,
      imageSeven: Ai7,
    },
  },
  {
    id: 19,
    portfolioImage: Mi1,
    image: Mi1,
    title: "Xiaomi Note 8 Pro Product Launch",
    category: "Event",
    gallery: {
      imageOne: Mi1,
      imageTwo: Mi2,
      imageThree: Mi3,
      imageFour: Mi4,
      imageFive: Mi5,
      imageSix: Mi6,
      imageSeven: Mi7,
      imageEight: Mi8,
      imageNine: Mi9,
      imageTen: Mi10,
      imageEleven: Mi11,
      imageTwelve: Mi12,
      imageThirteen: Mi13,
      imageFourteen: Mi14,
      imageFifteen: Mi15,
      imageSixteen: Mi16,
    },
  },
  {
    id: 20,
    portfolioImage: Vn1,
    image: Vn1,
    title: "Victorinox Gulfood 2021",
    category: "Event",
    gallery: {
      imageOne: Vn1,
      imageTwo: Vn2,
      imageThree: Vn3,
      imageFour: Vn4,
      imageFive: Vn5,
      imageSix: Vn6,
      imageSeven: Vn7,
    },
  },
  {
    id: 21,
    portfolioImage: main,
    image: main,
    title: "REDMAGIC Product Launch",
    category: "Event",
    gallery: {
      imageOne: Rd1,
      imageTwo: Rd2,
      imageThree: Rd3,
      imageFour: Rd4,
      imageFive: Rd5,
      imageSix: Rd6,
      imageSeven: Rd7,
      imageEight: Rd8,
      imageNine: Rd9,
      imageTen: Rd10,
    },
  },
  {
    id: 22,
    portfolioImage: Ken1,
    image: Ken1,
    title: "Kenwood Island Stands, Oman",
    category: "Event",
    gallery: {
      imageOne: Ken1,
      imageTwo: Ken2,
      imageThree: Ken3,
      imageFour: Ken4,
      imageFive: Ken5,
      imageSix: Ken6,
      imageSeven: Ken7,
      imageEight: Ken8,
      imageNine: Ken9,
    },
  },
];
