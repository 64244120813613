import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import SectionTitle from "../sectionTitle/SectionTitle";
// import PortfolioData from "../../data/portfolio/PortfolioData.json";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { portfolioData } from "../../data/portfolio/GallryImage";

const alldata = portfolioData;
const Elements = () => {
  const history = useHistory();
  const handleImageClick = (id) => {
    history.push(`/portfolio/${id}`);
  };

  return (
    <>
      <SEO title="Gallery || Masterstroqe" />
      <Layout>
        <div className="main-content">
          {/* Start Elements Area  */}
          <div className="rwt-gallery-area rn-section-gapBottom">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 mb--20">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle="Our Showcase "
                    title="Portfolio Highlights"
                    description=""
                  />
                </div>
              </div>

              <div id="gallery" className="container-fluid">
                {alldata.map((item) => (
                  <img
                    src={item?.portfolioImage}
                    alt={item.title}
                    className="img-responsive grayscale"
                    onClick={() => handleImageClick(item.id)}
                  />
                ))}
              </div>

              <div id="myModal" className="modal fade" role="dialog">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default Elements;
