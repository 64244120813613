import React from "react";
import SEO from "../common/SEO";
import HeaderTwo from "../common/header/HeaderTwo";
import FooterFour from "../common/footer/FooterFour";
import Separator from "../elements/separator/Separator";
import GalleryOne from "../elements/gallery/Gallery";
import BackgroundImg from "../assets/images/images/tcl-roadshow/tcl1.webp";

const AboutUs = () => {
  return (
    <>
      <SEO title="About Us" />
      <main className="page-wrapper">
        <HeaderTwo
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-transparent"
        />

        {/* Start Slider Area  */}
        <div
          className="slider-area slider-style-1 height-850 bg_image"
          data-black-overlay="7"
          style={{
            backgroundImage: `url(${BackgroundImg}`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner pt--80 text-center">
                  <div>
                    <h3 className="rn-sub-badge">
                      <span className="theme-gradient">Portfolio</span>
                    </h3>
                  </div>
                  <h1 className="title display-one">
                    Explore Our Creative Canvas.
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        {/* Start Service Area  */}
        <div className="service-area rn-section-gapTop">
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <div className="content">
                  <h3 className="title">Welcome to our creative gallery.</h3>
                  <h3 className="title">
                    At Masterstroqe, we believe that every project is a canvas,
                    and every idea is a brushstroke that brings it to life.
                  </h3>
                </div>
              </div>
              <div className="col-lg-7">
                <p className="mb--10">
                  In each project, we strive not only to meet but to exceed
                  expectations. We blend strategy, creativity, and craftsmanship
                  to craft unique brand experiences that leave a lasting impact.
                  As you explore our portfolio, you'll find a diverse range of
                  projects that showcase our dedication to excellence.
                </p>
                <p className="mb--10">
                  From captivating exhibition stands to immersive event
                  experiences, strategic branding to innovative retail designs,
                  our work reflects our commitment to transforming ideas into
                  tangible, unforgettable realities. Each project is a journey,
                  and we invite you to embark on it with us.
                </p>
                <p className="mb--10">
                  Take a moment to browse through our portfolio and see the
                  stories we've brought to life. If you're inspired and
                  intrigued, we'd love to hear from you and discuss how we can
                  partner to create something extraordinary together.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Brand Area  */}
        <div className="rwt-brand-area pb--60 pt--30">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mt--10">
                {/* <PortFolio brandStyle="brand-style-2" /> */}
                <GalleryOne />
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area  */}

        <Separator />

        <FooterFour />
      </main>
    </>
  );
};

export default AboutUs;
