import React from "react";
import { Link } from "react-router-dom";
import ScrollTop from "./ScrollTop";
import { FiInstagram, } from "react-icons/fi";

const FooterFour = () => {
  return (
    <>
      <footer className="rn-footer footer-style-default no-border">
        <div className="copyright-area copyright-style-one no-border">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="copyright-right text-center text-md-right">
                  <p className="copyright-text">
                    © Masterstroqe {new Date().getFullYear()}{" "}
                    <a href="https://www.instagram.com/masterstroqe/" style={{marginLeft: '10px'}} target="_blank">
                      <FiInstagram />
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <ScrollTop />
    </>
  );
};

export default FooterFour;
