import React from "react";
import { portfolioData } from "../../data/portfolio/GallryImage";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import HeaderTwo from "../../common/header/HeaderTwo";

const BlogDetailsContent = () => {
  const { id } = useParams();
  const itemId = parseInt(id);

  if (isNaN(itemId)) {
    return <div>Invalid item ID</div>;
  }

  const item = portfolioData?.find((item) => item?.id === itemId);

  if (!item) {
    return <div>Item not found</div>;
  }

  const isVideo = (src) => {
    const videoExtensions = ["mp4", "webm", "ogg"];
    const extension = src.split(".").pop();
    return videoExtensions.includes(extension);
  };

  return (
    <>
      <HeaderTwo />
      <div className="post-page-area">
        <div className="post-page-banner mt-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="content text-center">
                  <div className="page-title">
                    <h1 className="theme-gradient">{item.title}</h1>
                  </div>

                  <div className="row mt-5">
                    {Object.values(item.gallery).map((mediaSrc, idx) => (
                      <div
                        key={idx}
                        className="col-md-6 mb-1"
                        style={{ marginTop: "20px" }}
                      >
                        {isVideo(mediaSrc) ? (
                          <video
                            className="w-100"
                            autoPlay
                            loop
                            muted
                            style={{ border: "1px solid #fff" }}
                          >
                            <source
                              src={mediaSrc}
                              type={`video/${mediaSrc.split(".").pop()}`}
                            />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <img
                            className="w-100"
                            src={mediaSrc}
                            alt={`Media ${idx + 1}`}
                            style={{ border: "1px solid #fff" }}
                          />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetailsContent;
