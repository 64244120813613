import React from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
// import HeaderOne from "../../common/header/HeaderOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import ServiceOne from "./ServiceOne";
import Separator from "../separator/Separator";
import FooterFour from "../../common/footer/FooterFour";
import BackgroundImg from "../../assets/images/images/victorianox/vic5.webp";

const Service = () => {
  return (
    <>
      <SEO title="Service || Masterstroqe" />
      <Layout>
        <div className="main-content">
          <div
            className="slider-area slider-style-1 height-850 bg_image"
            data-black-overlay="7"
            style={{
              backgroundImage: `url(${BackgroundImg}`,
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="inner pt--80 text-center">
                    <div>
                      <h3 className="rn-sub-badge">
                        <span className="theme-gradient">
                          Tailored Solutions For You
                        </span>
                      </h3>
                    </div>
                    <h1 className="title display-one">
                      Unlocking Creative Potential:
                      <br /> Our Services.
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="service-area rn-section-gapTop">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="content">
                    <h3 className="title">About Us</h3>
                    <p>
                      At Masterstroqe, we have a proven ability to fully
                      integrate our client’s marketing goals and deliver them
                      consistently in a myriad of communication environments
                      through all mediums. Our team of experienced managers,
                      creative designers, project managers and factory
                      facilities are all ready to serve your project needs. We
                      are an end-to-end advertisement agency specializing in
                      designing and fabrication, especially for exhibition
                      stands, retail shop n’ shops and management and branding
                      of events.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <h3 className="title">Why work with us ?</h3>
                  <h5>Experience Meets Innovation</h5>
                  <p className="mb--10">
                    At Masterstroqe we bring together years of industry
                    experience with a constant drive for innovation. Our team is
                    a dynamic blend of seasoned professionals and creative
                    thinkers, allowing us to provide you with a unique
                    perspective on your projects.
                  </p>
                  <h5>Tailored Solutions</h5>
                  <p>
                    No two projects are the same, and we understand that. That's
                    why we offer fully customized solutions to meet your
                    specific needs. Our services are designed to be versatile,
                    adapting to the uniqueness of your brand and vision.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="service-area rn-section-gapTop">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="content">
                    <h3 className="title">
                      At Masterstroqe, we take pride in offering a diverse range
                      of services that are meticulously designed to meet the
                      unique needs of our clients across the Middle East.
                    </h3>
                  </div>
                </div>
                <div className="col-lg-6">
                  <p className="mb--10">
                    Our commitment to excellence, innovation, and creativity is
                    at the core of everything we do. We understand that every
                    brand and project is distinct, which is why our services are
                    both comprehensive and customizable.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* Start Service Area  */}
          <div className="rn-service-area rn-section-gap">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle="Our Expertise                    "
                    title="Our Services"
                    // description="There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration."
                  />
                </div>
              </div>
              <ServiceOne
                serviceStyle="service__style--1 bg-color-blackest radius mt--25"
                textAlign="text-center"
              />
            </div>
          </div>
          {/* End Service Area  */}

          <Separator />
        </div>
      </Layout>
      <FooterFour />
    </>
  );
};

export default Service;
