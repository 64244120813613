import React from "react";
import { FiActivity, FiCast, FiMap } from "react-icons/fi";
import { FaShoppingBasket } from "react-icons/fa";
import { AiOutlineUserSwitch } from "react-icons/ai";
import { SiProducthunt } from "react-icons/si";
import { FaMapMarkerAlt } from "react-icons/fa";
import { AiOutlineRise } from "react-icons/ai";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
  {
    icon: <FiActivity />,
    title: "Strategic Branding & Design",
    description:
      "Our team of creative strategists and designers collaborate closely with clients to develop unique brand identities and design solutions that resonate with their target audiences. We go beyond aesthetics to create strategic brand experiences that drive meaningful connections.",
  },
  {
    icon: <FiCast />,
    title: "Specialized Exhibition Stands & Event Branding",
    description:
      " With our expertise in designing and fabricating exhibition stands, we create bespoke environments that showcase your brand's unique identity and attract attention. Our meticulous attention to detail ensures that every element aligns with your brand message, creating memorable experiences for visitors.",
  },
  {
    icon: <FiMap />,
    title: "Roadshow",
    description:
      "Take your brand on an unforgettable journey with our expertly managed roadshow solutions. We understand the power of face-to-face interactions and the impact they can have on building brand loyalty. Our team specializes in planning and executing dynamic experiences that allow you to connect with your target audience in multiple locations.",
  },
  {
    icon: <FaShoppingBasket />,
    title: "Shop in Shop Solutions",
    description:
      "We specialize in creating innovative shop n’ shop concepts that enhance your brand's presence within retail environments. Through strategic design and placement, we enhance visibility and create engaging shopping experiences for customers.",
  },
  {
    icon: <AiOutlineUserSwitch />,
    title: "Outdoor Activations",
    description:
      "Harness the power of outdoor spaces with our creative and captivating activations. From eye-catching billboards that dominate cityscapes to engaging outdoor campaigns that make a lasting impression, we excel in leveraging outdoor spaces to amplify your brand message. Our innovative approach captivates audiences, creating memorable brand experiences that resonate and inspire action.",
  },
  {
    icon: <SiProducthunt />,
    title: "Product Activation",
    description:
      "We excel in activating your products in the market, creating buzz and driving consumer engagement. Our creative activation strategies effectively communicate your product's unique selling points and generate excitement among your target audience.",
  },
  {
    icon: <AiOutlineRise />,
    title: "Kiosks",
    description:
      "Enhance your brand's visibility and interaction with our customized kiosk solutions. We design and build interactive and visually appealing kiosks that captivate customers and deliver a seamless brand experience.",
  },
  {
    icon: <FaMapMarkerAlt />,
    title: "Captivating Event Experiences",
    description:
      " From large-scale corporate events to intimate brand activations, our experienced event management team brings your vision to life. We meticulously plan, curate, and execute immersive event experiences that leave a lasting impact on attendees and elevate your brand.",
  },
];

const ServiceOne = ({ textAlign, serviceStyle }) => {
  return (
    <div className="row row--15 service-wrapper">
      {ServiceList.map((val, i) => (
        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i} >
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
            
          >
            <div className={`service ${serviceStyle} ${textAlign}`} style={{height: '550px'}}>
              <div className="icon">{val.icon}</div>
              <div className="content">
                <h4 className="title w-600">
                  <Link
                    to="#service"
                    dangerouslySetInnerHTML={{ __html: val.title }}
                  ></Link>
                </h4>
                <p
                  className="description b1 color-gray mb--0"
                  dangerouslySetInnerHTML={{ __html: val.description }}
                ></p>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};
export default ServiceOne;
